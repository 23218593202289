import request from '@/service/request'
// 设置/清空电表

/* 电表 批量清空
* regionIds {Array} regionId=安装了电表的宿舍
* */
export const batchClearElectricMeterApi = (params) => {
  return request.post('/stu/setChargingFee/clear', params)
}

/* 电表 单个 设置、清空
*  regionIds {Array} regionId=安装了电表的宿舍
*  value 电表度数，0~650，保留两位小数
*  */
export const setSingleElectricMeterApi = (params) => {
  return request.post('/stu/setChargingFee/setSingle', params)
}

/* 电表清空记录 宿舍列表 page
* setId{string} 根据这个id获取
*   */
export const pageDormFromClearMeterApi = (params) => {
  return request.post('/stu/setChargingFee/set/record/page', params)
}
