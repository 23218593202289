<template>
  <table-view
    class="electric-meter-set-record-view"
    :total="total"
    :query-info.sync="queryInfo"
    v-loading="loading"
    @reload-table="renderTable"
    style="padding-top: 0;"
  >
    <template #header>
      <view-search-form
        ref="searchFormRef"
        :queryInfo.sync="queryInfo"
        :filter-keys="['roomId']"
        @on-search="renderTable(1)">
        <el-form-item label="状态" label-width="50px">
          <el-select
            size="small"
            v-model="queryInfo.status"
            clearable
            filterable
            style="width: 120px;">
            <el-option
              v-for="{paramValue, paramName} in chargingStatusList"
              :label="paramName"
              :value="Number(paramValue)"
              :key="paramValue" />
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间" label-width="80px">
          <el-date-picker
            type="datetime"
            size="small"
            placeholder=""
            v-model="queryInfo.startDate"
            style="width: 190px"
            value-format="yyyy-MM-dd HH:mm:ss" />
        </el-form-item>
        <el-form-item label="结束时间" label-width="80px">
          <el-date-picker
            type="datetime"
            size="small"
            v-model="queryInfo.endDate"
            style="width: 190px"
            value-format="yyyy-MM-dd HH:mm:ss" />
        </el-form-item>
      </view-search-form>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe size="small">
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column prop="originalBalance" label="原始度数" width="120" align="center" />
      <el-table-column prop="afterBalance" label="设置后度数" width="120" align="center" />
      <el-table-column prop="batchName" label="批次名" width="170" align="center" />
      <el-table-column prop="afterBalance" label="设置日期" width="170" align="center">
        <template v-slot="{row}">
          {{ row.createTime|formatDate('YYYY-MM-DD HH:mm') }}
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template v-slot="{row}">
          <p v-if="row.status||row.status===0">
            <span
              :style="{color:row.status===3?'var(--color-danger)':''}">{{ $http.getParamName(row.status, chargingStatusList)
              }}</span>
            <span
              v-if="row.taskStatus">{{ $http.getParamName(row.status, chargingStatusList) !== $http.getParamName(row.taskStatus, taskStatusList) ? '(' + $http.getParamName(row.taskStatus, taskStatusList) + ')' : ''
              }}</span></p>
          <span v-else>--</span>
        </template>
      </el-table-column>
    </el-table>
  </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import { pageDormFromClearMeterApi } from '@/api/dorm/set-electric-meter-api'

export default {
  name: 'electricMeterSetRecordView',
  mixins: [tableView],
  props: {
    roomId: String
  },
  data () {
    return {
      queryInfo: {
        roomId: null,
        startDate: null,
        endDate: null,
        status: null
      },
      chargingStatusList: [], // 赠送电费到账状态（table展示数据用）
      taskStatusList: [] // 赠送电费到账状态（queryInfo过滤数据用）
    }
  },
  async mounted () {
    await Promise.all([
      this.$http.getParamListByKey('chargingStatus', this, 'chargingStatusList'),
      this.$http.getParamListByKey('taskStatus', this, 'taskStatusList')
    ])
  },
  methods: {
    // 获取设置记录列表
    async renderTable (pageNum) {
      if (!this.roomId) return
      this.queryInfo.roomId = this.roomId
      await this.getTableData(pageDormFromClearMeterApi, pageNum)
    }
  }
}
</script>
<style lang="scss">
.electric-meter-set-record-view {
  .el-table {
    .el-table__body-wrapper {
      .el-table__cell {
        padding: 0;
      }
    }
  }
}
</style>
