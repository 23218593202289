var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"add-dorm-room-dialog",attrs:{"title":(_vm.preTitle + "宿舍"),"visible":_vm.show,"close-on-click-modal":false,"width":"520px","before-close":_vm.cancel},on:{"update:visible":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('cancel-popover',{ref:"cancelRef",attrs:{"disabled":_vm.loadData.loading,"update-flag":_vm.updateFlag},on:{"update:disabled":function($event){return _vm.$set(_vm.loadData, "loading", $event)}}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.save('宿舍',_vm.addDormRoom,'保存成功！')}}},[_vm._v("确 定")])]},proxy:true}])},[_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"rules":_vm.rules,"label-width":"100px"}},[_c('el-row',[_c('el-col',{attrs:{"span":18}},[_c('el-form-item',{attrs:{"label":"所在楼栋","prop":"dormitoryId"}},[_c('el-select',{attrs:{"disabled":_vm.isSecondCollegeEditing},on:{"change":_vm.changeDormBuild},model:{value:(_vm.formData.dormitoryId),callback:function ($$v) {_vm.$set(_vm.formData, "dormitoryId", $$v)},expression:"formData.dormitoryId"}},_vm._l((_vm.dormBuildList),function(ref){
var dormitoryName = ref.dormitoryName;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":dormitoryName,"value":id}})}),1)],1)],1),(_vm.formData.sexType)?_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"prop":"sexType","label-width":"20px"}},[(_vm.formData.sexType&&_vm.formData.inRule!==4)?[_c('el-tag',{attrs:{"type":_vm.formData.sexType === 1?'success':'primary'}},[_vm._v(" "+_vm._s(_vm.formData.sexType === 1 ? '男生宿舍' : (_vm.formData.sexType === 2 ? '女生宿舍' : ''))+" ")])]:_vm._e()],2)],1):_vm._e()],1),_c('el-form-item',{attrs:{"label":"所在楼层","prop":"floorNum"}},[_c('el-select',{attrs:{"disabled":_vm.isSecondCollegeEditing},model:{value:(_vm.formData.floorNum),callback:function ($$v) {_vm.$set(_vm.formData, "floorNum", $$v)},expression:"formData.floorNum"}},_vm._l((_vm.floorNumList),function(num){return _c('el-option',{key:num,attrs:{"label":("第" + num + "层"),"value":num}})}),1)],1),_c('el-form-item',{attrs:{"label":"宿舍号","prop":"roomName"}},[_c('el-input',{attrs:{"disabled":_vm.isSecondCollegeEditing},model:{value:(_vm.formData.roomName),callback:function ($$v) {_vm.$set(_vm.formData, "roomName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.roomName"}})],1),_c('el-form-item',{attrs:{"label":"宿舍类型","prop":"roomTypeId"}},[_c('el-select',{attrs:{"disabled":_vm.isSecondCollegeEditing},model:{value:(_vm.formData.roomTypeId),callback:function ($$v) {_vm.$set(_vm.formData, "roomTypeId", $$v)},expression:"formData.roomTypeId"}},_vm._l((_vm.roomTypeList),function(ref){
var roomTypeName = ref.roomTypeName;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":roomTypeName,"value":id}})}),1)],1),(!_vm.formData.sexType)?_c('el-form-item',{attrs:{"label":"入住性别","prop":"sexType"}},[_c('el-radio-group',{attrs:{"disabled":_vm.isSecondCollegeEditing},model:{value:(_vm.formData.sexType),callback:function ($$v) {_vm.$set(_vm.formData, "sexType", $$v)},expression:"formData.sexType"}},_vm._l((_vm.$store.state.systemParam.sexType),function(ref){
var name = ref.name;
var type = ref.type;
return _c('el-radio',{key:type,staticStyle:{"margin-right":"10px"},attrs:{"label":type}},[_vm._v(_vm._s(name)+"生宿舍 ")])}),1)],1):_vm._e(),_c('el-form-item',{attrs:{"label":"入住规则"}},[_c('el-radio-group',{attrs:{"size":"small"},on:{"change":_vm.handleInRule},model:{value:(_vm.formData.inRule),callback:function ($$v) {_vm.$set(_vm.formData, "inRule", $$v)},expression:"formData.inRule"}},_vm._l((_vm.dormRuleList),function(ref){
var paramValue = ref.paramValue;
var paramName = ref.paramName;
return _c('el-radio-button',{key:paramValue,attrs:{"label":Number(paramValue)}},[_vm._v(" "+_vm._s(paramName)+" ")])}),1)],1),(_vm.formData.inRule === 2)?_c('el-form-item',{attrs:{"label":"入住专业","prop":"majorId"}},[_c('el-select',{attrs:{"filterable":""},on:{"change":_vm.changeMajor},model:{value:(_vm.formData.majorId),callback:function ($$v) {_vm.$set(_vm.formData, "majorId", $$v)},expression:"formData.majorId"}},_vm._l((_vm.majorList),function(ref){
var majorName = ref.majorName;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":majorName,"value":id}})}),1)],1):_vm._e(),(_vm.formData.inRule === 1)?_c('el-form-item',{attrs:{"label":"所属院系"}},[_c('el-select',{attrs:{"filterable":""},model:{value:(_vm.formData.collegeId),callback:function ($$v) {_vm.$set(_vm.formData, "collegeId", $$v)},expression:"formData.collegeId"}},_vm._l((_vm.collegeList),function(ref){
var collegeName = ref.collegeName;
var id = ref.id;
return _c('el-option',{directives:[{name:"show",rawName:"v-show",value:(collegeName!=='继续教育学院'),expression:"collegeName!=='继续教育学院'"}],key:id,attrs:{"label":collegeName,"value":id}})}),1)],1):_vm._e(),(_vm.formData.inRule!==4)?_c('el-form-item',{attrs:{"label":"年级限制"}},[_c('el-select',{attrs:{"clearable":""},model:{value:(_vm.formData.grade),callback:function ($$v) {_vm.$set(_vm.formData, "grade", $$v)},expression:"formData.grade"}},_vm._l((_vm.$store.state.systemParam.grade),function(ref){
var paramValue = ref.paramValue;
return _c('el-option',{key:paramValue,attrs:{"label":paramValue,"value":Number(paramValue)}})}),1)],1):_vm._e(),_c('el-form-item',{attrs:{"label":"是否启用"}},[_c('el-radio-group',{attrs:{"size":"small"},model:{value:(_vm.formData.startUpFlag),callback:function ($$v) {_vm.$set(_vm.formData, "startUpFlag", $$v)},expression:"formData.startUpFlag"}},[_c('el-radio-button',{attrs:{"label":1}},[_vm._v("是")]),_c('el-radio-button',{attrs:{"label":0}},[_vm._v("否")])],1)],1),_c('el-form-item',{attrs:{"label":"备注","prop":"remarks"}},[_c('el-input',{model:{value:(_vm.formData.remarks),callback:function ($$v) {_vm.$set(_vm.formData, "remarks", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.remarks"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }