<!--设置宿舍的设备码-->
<template>
  <el-form ref="form" :model="formData" :rules="rules" label-width="140px" style="margin-top: 40px;">
    <el-form-item label="房间号(厂家标识)" prop="regionId">
      <tool-tips-group is-flex tips-content="设置了房间号 跟 电表设备ID后，再打开本弹窗，会显示宿舍的设置记录、赠送记录等">
        <el-input v-model.trim="formData.regionId" />
      </tool-tips-group>
    </el-form-item>
    <el-form-item label="电表设备ID" prop="electricDeviceId">
      <tool-tips-group is-flex tips-content="设置了房间号 跟 电表设备ID后，再打开本弹窗，会显示宿舍的设置记录、赠送记录等">
        <el-input v-model.trim="formData.electricDeviceId" />
      </tool-tips-group>
    </el-form-item>
    <el-form-item label="水表设备ID" prop="waterDeviceId">
      <el-input v-model.trim="formData.waterDeviceId" />
    </el-form-item>
    <el-form-item label="水表设备码" prop="waterSerialNo">
      <el-input v-model.trim="formData.waterSerialNo" />
    </el-form-item>
  </el-form>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import { copyData } from '@/utils/common-js'

export default {
  name: 'setDormDeviceIdForm',
  mixins: [dialog],
  props: {
    parentData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      formData: { // 宿舍信息
        id: null,
        electricDeviceId: '',
        regionId: '',
        waterDeviceId: '',
        waterSerialNo: '',
        startUpFlag: null,
        collegeId: this.$store.state.login.userInfo.collegeId || ''
      },
      rules: {
        regionId: [
          {
            message: '请填写房间号',
            trigger: 'blur'
          },
          {
            max: 200,
            message: '仅限200字',
            trigger: 'blur'
          }
        ],
        electricDeviceId: [
          {
            message: '请填写电表设备ID',
            trigger: 'blur'
          },
          {
            max: 200,
            message: '仅限200字',
            trigger: 'blur'
          }
        ],
        waterDeviceId: [
          {
            message: '请填写水表设备ID',
            trigger: 'blur'
          },
          {
            max: 200,
            message: '仅限200字',
            trigger: 'blur'
          }
        ],
        waterSerialNo: [
          {
            message: '请填写水表设备码',
            trigger: 'blur'
          },
          {
            max: 200,
            message: '仅限200字',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  async mounted () {
    await this.$nextTick(() => {
      if (this.parentData) {
        copyData(this.parentData, this.formData)
      }
    })
  },
  methods: {}
}
</script>

<style scoped>

</style>
