<!--电表使用记录-->
<template>
  <table-view
    class="free-electricity-recharge-view"
    :total="total"
    :query-info.sync="queryInfo"
    style="padding-top: 0;"
    @reload-table="renderTable">
    <template #header>
      <view-search-form
        ref="searchFormRef"
        :query-info.sync="queryInfo"
        :show-clear-btn="false"
        @on-search="renderTable(1)">
        <el-form-item label="赠送日期(年月)" label-width="70">
          <el-date-picker
            type="month"
            size="small"
            v-model="queryInfo.month"
            format="yyyyMM"
            value-format="yyyyMM" />
        </el-form-item>
      </view-search-form>
    </template>
    <el-table :data="tableData" style="width: 100%" border stripe size="small">
      <el-table-column type="index" label="ID" width="60" align="center" />
      <el-table-column label="赠送日期（年月）" min-width="140" align="center">
        <template v-slot="{row}">
          <span v-if="row.monthStr">{{ row.monthStr }}</span>
          <el-tag v-else>自定义赠送</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="peopleCount" label="赠送人数" min-width="80" align="center" />
      <el-table-column prop="degreesNum" label="赠送总额（度）" min-width="140" align="center" />
      <el-table-column label="到账状态" width="200" align="center">
        <template v-slot="{row}">
          <p v-if="row.taskStatus">{{ $http.getParamName(row.status, chargingStatusList)
            }}
            {{ $http.getParamName(row.status, chargingStatusList) !== $http.getParamName(row.taskStatus, taskStatusList) ?
              '(' + $http.getParamName(row.taskStatus, taskStatusList) + ')' : ''
            }}
          </p>
          <span v-else>--</span>
        </template>
      </el-table-column>
    </el-table>
  </table-view>

</template>

<script>
import tableView from '@/vue/mixins/table-view'
import { pageFreeElectricRecordApi } from '@/api/dorm/free-electric-api'

export default {
  name: 'freeElectricityRechargeView',
  mixins: [tableView],
  props: {
    roomId: String
  },
  data () {
    return {
      chargingStatusList: [], // 赠送电费到账状态（table展示数据用）
      taskStatusList: [], // 赠送电费到账状态（queryInfo过滤数据用）
      queryInfo: {
        roomId: null,
        month: null
      }
    }
  },
  async mounted () {
    await Promise.all([
      this.$http.getParamListByKey('chargingStatus', this, 'chargingStatusList'),
      this.$http.getParamListByKey('taskStatus', this, 'taskStatusList')
    ])
  },
  methods: {
    async renderTable (pageNum) {
      if (this.roomId) {
        this.queryInfo.roomId = this.roomId
        await this.getTableData(pageFreeElectricRecordApi, pageNum)
      } else {
        this.$message.error('赠送记录获取失败！')
      }
    }
  }
}
</script>
