<template>
  <el-dialog
    title="批量清空确认"
    :visible="show"
    :append-to-body="false"
    :before-close="close"
    width="420px"
  >
    <p class="confirm-message">初始化电表后，数据将无法批量还原。</p>
    <p class="confirm-message">是否确认将选中的<b class="bg-danger">{{ confirmInfo.roomIds.length }}个</b>宿舍的电表度数初始化为 <b>0</b> 度？
    </p>
    <template #footer>
      <el-button @click="close">放弃清空</el-button>
      <el-button type="danger" :disabled="num>0" @click="handleConfirm">确认清空{{ num > 0 ? `(${num})` : ''
        }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'

export default {
  name: 'confirmClearMeterDialog',
  mixins: [dialog],
  props: {
    confirmInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      num: 5
    }
  },
  mounted () {
    setInterval(() => {
      if (this.num > 0) this.num--
    }, 1000)
  },
  methods: {
    handleConfirm () {
      this.$emit('on-confirm')
      this.close()
    }
  }
}
</script>

<style scoped>

</style>
