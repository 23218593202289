<template>
  <el-dialog
    class="add-dorm-room-dialog"
    :title="`${preTitle}宿舍`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="520px"
    :before-close="cancel">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="100px"
    >
      <el-row>
        <el-col :span="18">
          <el-form-item label="所在楼栋" prop="dormitoryId">
            <!-- 二级学院的宿舍楼、楼层、宿舍号、床位号不可编辑，只能新增 -->
            <el-select v-model="formData.dormitoryId" @change="changeDormBuild" :disabled="isSecondCollegeEditing">
              <el-option
                v-for="{dormitoryName, id} in dormBuildList"
                :label="dormitoryName"
                :value="id"
                :key="id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="formData.sexType">
          <el-form-item prop="sexType" label-width="20px">
            <!--  formData.inRule=4为教师宿舍，不限制性别-->
            <template v-if="formData.sexType&&formData.inRule!==4">
              <el-tag :type="formData.sexType === 1?'success':'primary'">
                {{ formData.sexType === 1 ? '男生宿舍' : (formData.sexType === 2 ? '女生宿舍' : '') }}
              </el-tag>
            </template>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="所在楼层" prop="floorNum">
        <el-select v-model="formData.floorNum" :disabled="isSecondCollegeEditing">
          <el-option
            v-for="num in floorNumList"
            :label="`第${num}层`"
            :value="num"
            :key="num"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="宿舍号" prop="roomName">
        <el-input v-model.trim="formData.roomName" :disabled="isSecondCollegeEditing" />
      </el-form-item>
      <el-form-item label="宿舍类型" prop="roomTypeId">
        <el-select v-model="formData.roomTypeId" :disabled="isSecondCollegeEditing">
          <el-option
            v-for="{roomTypeName,id} in roomTypeList"
            :label="roomTypeName"
            :value="id"
            :key="id"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-if="!formData.sexType" label="入住性别" prop="sexType">
        <el-radio-group v-model="formData.sexType" :disabled="isSecondCollegeEditing">
          <el-radio style="margin-right: 10px;" v-for="{name, type} in $store.state.systemParam.sexType" :key="type"
                    :label="type">{{ name }}生宿舍
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="入住规则">
        <el-radio-group v-model="formData.inRule" size="small" @change="handleInRule">
          <el-radio-button v-for="{paramValue,paramName} in dormRuleList" :key="paramValue"
                           :label="Number(paramValue)">
            {{ paramName }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="formData.inRule === 2" label="入住专业" prop="majorId">
        <el-select v-model="formData.majorId" @change="changeMajor" filterable>
          <el-option
            v-for="{majorName, id} in majorList"
            :label="majorName"
            :value="id"
            :key="id"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-if="formData.inRule === 1" label="所属院系">
        <!--  继续教育学院 不显示 -->
        <el-select v-model="formData.collegeId" filterable>
          <el-option
            v-for="{collegeName, id} in collegeList"
            v-show="collegeName!=='继续教育学院'"
            :label="collegeName"
            :value="id"
            :key="id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="年级限制" v-if="formData.inRule!==4">
        <el-select v-model="formData.grade" clearable>
          <el-option v-for="{paramValue} in $store.state.systemParam.grade"
                     :key="paramValue"
                     :label="paramValue"
                     :value="Number(paramValue)"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="是否启用">
        <el-radio-group v-model="formData.startUpFlag" size="small">
          <el-radio-button :label="1">是</el-radio-button>
          <el-radio-button :label="0">否</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save('宿舍',addDormRoom,'保存成功！')">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addDormRoom, getDormRoomById } from '@/api/dorm'
import dialog from '@/vue/mixins/dialog'
import CampusSchMajorClasses from '@/vue/mixins/campusSchMajorClasses'

export default {
  name: 'addDormRoom',
  mixins: [dialog, CampusSchMajorClasses],
  props: {
    isSecondCollege: Boolean,
    dormInfo: {
      type: Object,
      default: () => ({
        floorAmount: 0
      })
    },
    dormRuleList: { // 入住规则 list
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      formData: {
        id: null,
        majorId: '', // 专业
        collegeId: '', // 院系
        dormitoryId: '', // 宿舍楼
        floorNum: 0, // 楼层数
        roomName: '', // 宿舍名
        roomTypeId: '', // 宿舍类型
        // mixtureFlag: 0, // 混合宿舍 1 是，0 否
        inRule: 1, // 入住规则 1 学院，2 专业 3 混合
        bedNum: '', // 床位数
        grade: new Date().getFullYear(), // 年级限制
        emptyBedNum: '', // 空床位数
        noEmptyBedNum: '', // 已有人的床位数
        startUpFlag: 1, // 是否启用 1 是 0 否
        sexType: 1, // 1男 2女
        remarks: ''
      },
      isSecondCollegeEditing: false, // 二级学院的编辑状态时，此项不可编辑
      roomTypeList: [],
      dormBuildList: [],
      floorNumList: 0,
      rules: {
        dormitoryId: {
          required: true,
          message: '请选择楼栋',
          trigger: 'blur'
        },
        floorNum: {
          required: true,
          message: '请选择楼层',
          trigger: 'blur'
        },
        roomName: [
          {
            required: true,
            message: '请输入宿舍号',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.limit20WordsObj
        ],
        roomTypeId: {
          required: true,
          message: '请选择宿舍类型',
          trigger: 'blur'
        }
      }
    }
  },
  async mounted() {
    await Promise.all([
      this.getMajorList(),
      this.getCollegeList(),
      this.getDormRoomTypeList(),
      this.getRoomBuildList()
    ])
    this.$nextTick(async () => {
      // 获取楼层
      this.floorNumList = this.dormInfo.floorAmount
      if (this.id) {
        await this.getDataById(this.id, getDormRoomById)
        await this.getMajorById(this.formData.majorId)
        if (this.isSecondCollege) {
          this.isSecondCollegeEditing = true
        }
      } else {
        if (this.formData.inRule) {
          this.handleInRule(1)
        }
        this.formData.dormitoryId = this.dormInfo.dormitoryInfoId
        this.formData.floorNum = this.dormInfo.floorNum
        this.formData.sexType = this.dormInfo.building.sexType
        this.setInitData()
      }
    })
  },
  methods: {
    addDormRoom, // 保存api
    // 根据id获取专业信息
    getMajorById(majorId) {
      if (majorId) {
        this.$http.getMajorById(majorId).then(res => {
          this.formData.collegeId = res.data.collegeId
        })
      }
    },
    // 选择专业
    changeMajor(majorId) {
      this.getMajorById(majorId)
    },
    // 获取宿舍类型
    async getDormRoomTypeList() {
      try {
        const res = await this.$http.getDormRoomTypeListQuery({})
        this.roomTypeList = res.data
      } catch (e) {
      }
    },
    // 获取宿舍楼栋
    async getRoomBuildList() {
      try {
        const res = await this.$http.getDormBuildQuery({})
        this.dormBuildList = res.data
      } catch (e) {
      }
    },
    // 选择楼栋
    changeDormBuild(dormitoryId) {
      this.formData.sexType = null
      this.formData.floorNum = ''
      if (dormitoryId) {
        const _result = this.dormBuildList.filter(
          (item) => item.id === dormitoryId
        )[0]
        this.floorNumList = _result.floorAmount
        this.formData.sexType = _result.sexType
      }
    },
    // 改变入住规则时，清空选中项目
    handleInRule(val) {
      switch (val) {
        case 1:
          this.formData.collegeId = this.$store.state.login.userInfo.collegeId || ''
          break
        case 4:
          this.formData.grade = ''
          break
        default:
          this.formData.collegeId = ''
          this.formData.majorId = ''
          this.formData.grade = new Date().getFullYear()
      }
    }
  }
}
</script>
<style lang="scss">
.add-dorm-room-dialog {
  .el-dialog {
    margin-top: 5vh !important;
  }
}
</style>
