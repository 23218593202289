<!-- 缴费项目绑定学生 -->
<template>
  <div>
    <el-dialog
      class="batch-clear-electric-meter-dialog"
      title="批量清空电表"
      :visible.sync="show"
      :close-on-click-modal="false"
      :before-close="closed"
      width="1300px"
    >
      <div v-loading="loading" class="dorm-tree flex-column oh">
        <div class="dorm-tree-selector">
          <div class="flex dorm-tree-selector_header">
            <ElInput class="search-input" v-model="keyword" placeholder="搜索" clearable>
              <template #prefix>
                <span class="el-icon-search"></span>
              </template>
            </ElInput>
            <el-checkbox class="ml-1" v-model="isCheckAll"
                         :disabled="!!visibleNoChecks||!!visibleOnlyChecks"
                         true-label="isCheckAll"
                         @change="handleCheckAll"
                         style="line-height: 40px;">
              <span style="vertical-align: -1px;">全选</span>
            </el-checkbox>
            <el-checkbox :disabled="!!keyword" class="ml-1" true-label="visibleNoChecks"
                         v-model="visibleNoChecks"
                         @change="handleShowChecks"
                         style="line-height: 40px;"><span
              style="vertical-align: -1px;">只显示未勾选</span>
            </el-checkbox>
            <el-checkbox :disabled="!!keyword" class="ml-1" true-label="visibleOnlyChecks"
                         v-model="visibleOnlyChecks"
                         @change="handleShowChecks"
                         style="line-height: 40px;"><span
              style="vertical-align: -1px;">只显示已勾选</span>
            </el-checkbox>
            <tool-tips-group
              tips-content="【全选】会自动获取所有设置了电表设备码的宿舍。<br/>未勾选上说明该宿舍未安装电表。<br/>存在【搜索关键字】时，这三个过滤功能禁用。"
              class="mr-1 ml-1" />
          </div>
          <div class="oh" style="height: calc(100vh - 320px);">
            <el-scrollbar style="height: calc(100vh - 304px);">
              <el-tree
                ref="treeRef"
                :data="treeData"
                :props="treeProps"
                node-key="id"
                show-checkbox
                default-expand-all
                :expand-on-click-node="false"
                :filter-node-method="filterNode"
                @check="handleCheck" />
            </el-scrollbar>
          </div>
        </div>
      </div>
      <template #footer>
        <el-button @click="closed">关闭</el-button>
        <el-button type="danger" @click="handleConfirm">批量清空电表</el-button>
      </template>
    </el-dialog>
    <confirm-clear-meter-dialog :visible.sync="visibleConfirmClearDialog" v-if="visibleConfirmClearDialog"
                                :confirm-info="formData" @on-confirm="handleClearMeter" />
  </div>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import { treeDormTreeHasStuApi } from '@/api/dorm'
import { batchClearElectricMeterApi } from '@/api/dorm/set-electric-meter-api'
import dormTree from '@/vue/mixins/dorm-tree'
import ConfirmClearMeterDialog from '@/views/pages/dormManage/dialog/component/confirmClearMeterDialog.vue'

export default {
  name: 'batchClearElectricMeterDialog',
  components: { ConfirmClearMeterDialog },
  mixins: [dialog, dormTree],
  props: {
    freeTypeList: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      loading: false,
      visibleConfirmClearDialog: false, // 清空确认弹窗
      formData: {
        batchName: '',
        roomIds: []
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.renderTree()
    })
  },
  methods: {
    async renderTree () {
      this.loading = true
      try {
        // filterLockFlag=1 只有南校区有电子锁，通过设置 此字段获取所有南校区宿舍
        // const res = await getDormTree({ filterLockFlag: 1 })
        const res = await treeDormTreeHasStuApi({ stuFlag: 0 })
        // this.recursiveDisabledDorms(res.data)
        await this.$nextTick()
        this.treeData = res.data
      } catch (e) {
        this.$message.error('系统出错,请稍后重试')
        this.close()
      } finally {
        this.loading = false
      }
    },
    // 递归获取宿舍（未安装电表的禁选）
    recursiveDisabledDorms (arr) {
      for (const item of arr) {
        if (item.nodeType && item.nodeType === 'dormitoryRoom') {
          if (!item.regionId) {
            Object.assign(item, { disabled: true })
          }
        } else if (item?.children?.length) {
          this.recursiveDisabledDorms(item.children)
        }
      }
    },
    handleConfirm () {
      if (!this.formData.roomIds.length) {
        this.$message.error('请先勾选带电表的宿舍！')
        return
      }
      this.visibleConfirmClearDialog = true
    },
    // 批量清空
    async handleClearMeter () {
      this.loading = true
      this.$prompt('请输入批次名', '批量清空电表', {
        inputPattern: /^.+$/,
        inputErrorMessage: '批次名为必填！'
      }).then(async ({ value }) => {
        try {
          await batchClearElectricMeterApi({
            roomIds: this.formData.roomIds,
            batchName: value
          })
          this.$message.success('批量清空请求发送成功，选中的电表将在10到15分钟以内清零！')
          this.close()
        } catch (e) {
          this.$message.error('清空失败，请稍后重试！')
        } finally {
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss">
.batch-clear-electric-meter-dialog {
  .el-dialog {
    margin-top: 20px !important;
    margin-bottom: 0 !important;

    .el-dialog__body {
      padding-top: 0;

      .dorm-tree {
        height: calc(100vh - 260px);

        .el-icon-search {
          line-height: 40px;
        }

        .dorm-tree-selector {
          @include flex-column;
          border: 1px solid var(--border-light);

          .dorm-tree-selector_header {
            border-bottom: 1px solid var(--border-light);
          }

          .search-input {
            @include flex-1;
            border-right: 1px solid var(--border-light);

            .el-input__inner {
              border: 0;
            }

            .el-input__wrapper {
              box-shadow: none;
            }
          }

          .el-tree {
            > .el-tree-node {
              height: auto;
              overflow: hidden;

              > .el-tree-node__content {
                margin-top: var(--space-primary);
                margin-bottom: 6px;
                background: #f3f5fb;
              }

              > .el-tree-node__children {
                > .el-tree-node {
                  height: auto;
                  overflow: hidden;

                  > .el-tree-node__children {
                    width: 100%;
                    @include flex;
                    flex-direction: row; /* 指定主轴方向为水平 */
                    flex-wrap: wrap; /* 设为多行换行 */

                    > .el-tree-node {
                      margin-left: 52px;
                      width: 11%;
                      margin-bottom: 1em;

                      > .el-tree-node__content {
                        padding-left: 12px !important;
                      }
                    }

                    .el-tree-node__expand-icon {
                      display: none;
                    }
                  }
                }
              }

            }
          }

        }

      }

    }
  }
}
</style>
