<template>
  <table-view
    :show-page="false"
  >
    <template #header>
      <div class="header-button">
        <!-- isSetElectricMeter ：用电管理-水电表模块的功能 -->
        <template v-if="isSetElectricMeter">
          <div v-role="['开发人员']" style="display: flex;margin-right: 20px; align-items: center;">
            <tool-tips-group
              tips-content="打开后，设置了按钮权限的角色能看到【批量清空电表】按钮">
              显示【批量清空电表】按钮
            </tool-tips-group>
            ：
            <el-switch
              v-model="batchSetMeterFlag.paramValue"
              @change="handleBatchSetMeterFlag"
              active-color="var(--color-success)"
              active-value="true"
              active-text="是"
              inactive-text="否"
              inactive-value="false"
            />
          </div>
          <el-button v-if="JSON.parse(batchSetMeterFlag.paramValue)&&hasPermiKeys(['electricMeter:batch:clear'])"
                     type="danger" size="small"
                     @click="showSubDialog('visibleBatchSetMeterDialog','batchSetMeterDialogRef')"
          >批量清空电表度数
          </el-button>
        </template>
        <!--   二级学院、公寓服务中心-宿舍设置     -->
        <template v-else>
          <el-button type="success" size="small" @click="exportDormRoom()">导出数据
          </el-button>
          <upload-excel :permit-key="['dorm:room:edit','secondCollege:dorm:room:edit']"
                        :loading.sync="loading"
                        :api="$http.importExcelHttp.importDormRoom"
                        @on-download="$http.exportExcelHttp.exportDormRoomTemplate()"
                        @on-success="renderTable(1)" />
          <el-button
            v-if="hasPermiKeys(['dorm:room:edit','secondCollege:dorm:room:edit'])"
            type="primary"
            size="small"
            @click="showDialog()"
          >新增宿舍
          </el-button>
        </template>
      </div>
    </template>
    <!-- body -->
    <el-card>
      <el-row>
        <el-col :span="3" class="table-max-height">
          <el-scrollbar style="height: 100%">
            <div style="max-height: 70vh">
              <el-tree
                :data="dormFloorTree"
                accordion
                node-key="id"
                :default-expanded-keys="[currentFloorId]"
                :props="defaultProps"
                @node-click="handleNodeClick" />
            </div>
          </el-scrollbar>
        </el-col>
        <el-col :span="21">
          <div style="display: flex; align-items: center; margin-left: 20px">
            <div style="margin-right: 20px">
              <h4 v-if="currentFloorId">
                <span>{{ currentFloor.building.label }}-</span>
                <span>{{ currentFloor.label }}</span>
              </h4>
            </div>
            <div class="flex-1" v-if="!isSecondCollege">
              <el-radio-group v-model="dormRadio" size="small" @change="handleDormRule">
                <el-radio-button :label="-1">全部</el-radio-button>
                <el-radio-button v-for="{paramValue,paramName} in dormRuleList" :key="paramValue"
                                 :label="Number(paramValue)">
                  {{ paramName }}
                </el-radio-button>
              </el-radio-group>
            </div>
            <el-button
              type="danger"
              plain
              v-if="!isSetElectricMeter&&hasPermiKeys(['dorm:room:del', 'secondCollege:dorm:room:del'])"
              :disabled="!multipleSelection.length"
              size="small"
              @click="handleBatchDelete">
              批量删除
            </el-button>
          </div>
          <el-table
            max-height="600"
            :data="dormRoomList"
            style="width: 100%"
            v-loading="loading"
            border
            stripe
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" v-if="!isSetElectricMeter" />
            <el-table-column
              prop="roomName"
              label="宿舍号"
              width="80" />
            <el-table-column
              prop="dormitoryRoomType.roomTypeName"
              label="类型"
              min-width="120" />
            <el-table-column prop="sexType" label="入住性别" width="80" align="center">
              <template v-slot="{row}">
                {{ row.inRule === 4 ? '无限制' : (Number(row.sexType) === 1 ? '男' : '女') }}
              </template>
            </el-table-column>
            <el-table-column prop="collegeName" label="入住院系" width="150">
              <template v-slot="{row}">
                <span v-if="row.collegeName">{{
                    row.collegeName
                  }}</span>
                <span v-else>无限制</span>
              </template>
            </el-table-column>
            <el-table-column prop="grade" label="年级限制" width="80">
              <template v-slot="{row}">
                {{ row.grade ? row.grade : '无限制' }}
              </template>
            </el-table-column>
            <el-table-column label="入住专业" width="150">
              <template v-slot="{row}">
                <span v-if="Number(row.inRule) === 4">教师宿舍</span>
                <span v-if="Number(row.inRule) === 3">混合宿舍</span>
                <span v-if=" row.collegeName && Number(row.inRule) === 1">无限制</span>
                <span v-if="row.majorName && Number(row.inRule) === 2">{{ row.majorName }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="className" label="班级" width="150">
            </el-table-column> -->
            <el-table-column
              prop="dormitoryRoomType.imgUrl"
              label="布局图"
              width="130"
            >
              <template v-slot="{row}">
                <div class="image">
                  <el-image
                    style="width: 100px; height: 50px"
                    :src="$fileUrl + '' + row.dormitoryRoomType.imgUrl"
                    :preview-src-list="[
                      $fileUrl + '' + row.dormitoryRoomType.imgUrl
                    ]"
                    fit="cover"
                  ></el-image>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="入住规则" width="80">
              <template v-slot="{row}">
                {{ Number(row.inRule) === 1 ? '按学院' :
                (Number(row.inRule) === 2 ? '按专业' :
                  (Number(row.inRule) === 3 ? '混合宿舍' :
                    (Number(row.inRule) === 4 ? '教师宿舍' : ''))) }}
              </template>
            </el-table-column>
            <el-table-column prop="noEmptyBedNum" label="入住人数" width="80">
            </el-table-column>
            <el-table-column label="状态" width="100">
              <template v-slot="{row}">
                <el-tag
                  type="success"
                  v-if="Number(row.startUpFlag) === 1"
                >启用
                </el-tag>
                <el-tag type="danger" v-else>禁用</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="锁ID" width="100">
              <template v-slot="{row}">
                {{ row.lockFeatureCode }}
              </template>
            </el-table-column>
            <el-table-column prop="stuStatusCount" label="入住学生学籍状态" width="160">
              <template v-slot="{row}">
                <div v-for="(item, index) in row.stuStatusCount" :key="index">{{ item }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="remarks" label="备注" />
            <!--  isSetElectricMeter 设置水电表 -->
            <el-table-column v-if="isSetElectricMeter" prop="flowBalance" label="剩余电量(度)" width="110"
                             align="center" fixed="right" />
            <!--      （isSetElectricMeter=true）水电表设置 操作栏  start     -->
            <el-table-column v-if="isSetElectricMeter" label="操作" width="100" fixed="right">
              <template v-slot="{row}">
                <el-button
                  v-if="hasPermiKeys(['electricWaterMeter:view'])"
                  type="primary"
                  size="mini"
                  @click="showSubDialog('visibleElectricWaterMeterDialog', 'electricWaterMeterRef', row.id, row)"
                >水电表
                </el-button>
              </template>
            </el-table-column>
            <!--      （isSetElectricMeter=true） 水电表设置 操作栏  end     -->
            <!--      宿舍设置 操作栏  start     -->
            <el-table-column label="操作" width="220" fixed="right" v-else>
              <template v-slot="{row}">
                <!-- 1.管理员 2.所属学院 -->
                <template
                  v-if="(!row.collegeId||!$store.state.login.userInfo.collegeId||row.collegeId===$store.state.login.userInfo.collegeId)">
                  <el-button
                    v-if="hasPermiKeys(['dorm:room:edit','secondCollege:dorm:room:edit'])"
                    type="primary"
                    size="mini"
                    @click="showDialog(row.id)"
                  >编辑
                  </el-button>
                  <el-button
                    v-if="hasPermiKeys(['dorm:room:edit','secondCollege:dorm:room:edit'])"
                    type="primary"
                    size="mini"
                    @click="editLockCode(row)"
                  >设置门锁码
                  </el-button>
                  <template v-if="!isSecondCollege">
                    <el-button
                      v-if="hasPermiKeys(['dorm:room:del','secondCollege:dorm:room:del'])"
                      size="mini"
                      type="danger"
                      @click="del(deleteDormRoom, row.id, `宿舍-${row.roomName}`)"
                    >删除
                    </el-button>
                  </template>
                </template>
              </template>
            </el-table-column>
            <!--      宿舍设置 操作栏  end     -->
          </el-table>
        </el-col>
      </el-row>
    </el-card>
    <!-- 水电表 -->
    <ammeter-water-meter-dialog ref="electricWaterMeterRef" :visible.sync="visibleElectricWaterMeterDialog"
                                v-if="visibleElectricWaterMeterDialog" @on-close="renderTable" />
    <!--  添加编辑 dialog  -->
    <add-dorm ref="dialogRef" :is-second-college="isSecondCollege" :dorm-info="currentFloor"
              :visible.sync="visibleDialog" v-if="visibleDialog" :dorm-rule-list="dormRuleList"
              @on-close="renderTable" />
    <base-select-class
      :dialogData="selectClass"
      @confirm="bindClassHandle"
      @close="closeSelectClass" />
    <!-- 批量清空电表   -->
    <batch-clear-electric-meter-dialog ref="batchSetMeterDialogRef" :visible.sync="visibleBatchSetMeterDialog"
                                       v-if="visibleBatchSetMeterDialog" @on-close="renderTable" />
  </table-view>
</template>

<script>
import { deleteDormRoom, saveLockFeatureCodeApi, addDormRoom, deleteRoomBatch } from '@/api/dorm'
import addDorm from './dialog/addDormRoom.vue'
import tableView from '@/vue/mixins/table-view'
import ammeterWaterMeterDialog from '@/views/pages/dormManage/dialog/electricWaterMeterDialog.vue'
import { copyData } from '@/utils/common-js'
import { getSysParamsByKey, addParams } from '@/api/sysParams'
import { throttle, intersection } from 'lodash'
import BatchClearElectricMeterDialog from '@/views/pages/dormManage/dialog/component/batchClearElectricMeterDialog.vue'

export default {
  components: {
    BatchClearElectricMeterDialog,
    ammeterWaterMeterDialog,
    addDorm
  },
  mixins: [tableView],
  data() {
    return {
      selectClass: {
        id: '',
        isOpen: false,
        title: '分配班级',
        majorId: ''
      },
      visibleBatchSetMeterDialog: false, // 批量清空电表
      visibleElectricWaterMeterDialog: false, // 水电表
      // 多选 用来批量删除的
      multipleSelection: [],
      dormFloorTree: [],
      dormRoomList: [],
      // 选中的宿舍和宿舍楼
      currentFloor: {
        dormitoryInfoId: '',
        emptyBedNum: '',
        floorAmount: '',
        floorNum: '',
        id: '',
        label: '',
        noEmptyBedNum: '',
        nodeType: ''
      }, // 当前楼层
      currentFloorId: '',
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      dormRuleList: [], // 入住规则 list
      tableLoading: false,
      isSecondCollege: false, // 二级学院-宿舍设置
      isSetElectricMeter: false, // 用电管理-水电表模块
      collegeId: this.$store.state.login.userInfo.collegeId,
      dormRadio: -1,
      filterData: [],
      // 是否显示批量修改电表的按钮
      batchSetMeterFlag: {
        id: null,
        paramKey: '',
        paramName: '',
        paramType: null,
        paramValue: null
      }
    }
  },
  watch: {
    $route: {
      handler(to) {
        let _query = {}
        if (to.path === '/secondCollege/setDorm') {
          this.isSetElectricMeter = false
          this.isSecondCollege = true
          _query = {
            collegeId: this.$store.state.login.userInfo.collegeId
          }
        } else if (to.path === '/repair/electricMeterManage') {
          this.isSetElectricMeter = true
        } else {
          this.isSetElectricMeter = false
          this.isSecondCollege = false
        }
        this.getDormTree(_query)
      },
      deep: true,
      immediate: true
    }
  },

  computed: {
    // 判断是否楼栋管理员or超级管理员
    isEditor() {
      let _editor = false
      // dormitoryIdList 里存在本宿舍楼id时，代表为本楼栋管理员，可以编辑
      if (this.currentFloor?.dormitoryInfoId && this.$store.state.login.userInfo?.dormitoryIdList?.length) {
        _editor = this.$store.state.login.userInfo.dormitoryIdList.includes(this.currentFloor.dormitoryInfoId)
      }
      return _editor
    }
  },
  async mounted() {
    await Promise.all([
      this.getBatchSetMeterFlag(),
      this.$http.getParamListByKey('dormRules', this, 'dormRuleList')
    ])
  },
  methods: {
    deleteDormRoom, // 删除api
    // 拥有编辑权限的可以操作数据
    hasPermiKeys(arr = []) {
      return this.isEditor || intersection(this.$store.state.login.userInfo.paraNameList, arr)?.length
    },
    // 分配班级
    bindClassHandle(data) {
      this.selectClass.isOpen = false
      this.addDormHandle({
        id: this.selectClass.id,
        classesId: data.classesId
      })
    },
    // 分配班级请求
    addDormHandle(data) {
      addDormRoom(data).then(() => {
        this.$message.success('分配成功')
        this.renderTable()
      })
    },
    // 打开分配班级
    openBindClass(data) {
      this.selectClass = {
        id: data.id,
        isOpen: true,
        title: '分配班级',
        majorId: data.majorId
      }
    },
    // 根据入住规则获取宿舍列表
    handleDormRule(val) {
      switch (val) {
        case -1:
          this.dormRoomList = this.filterData
          break
        default:
          this.dormRoomList = this.filterData.filter(({ inRule }) => Number(inRule) === val)
          break
      }
    },
    // 条件查询宿舍
    renderTable() {
      this.loading = true
      this.$http
        .getDormRoomQuery({
          dormitoryId: this.currentFloor.dormitoryInfoId,
          floorNum: this.currentFloor.floorNum,
          hasElectric: this.isSetElectricMeter ? 1 : 0
        })
        .then((res) => {
          this.loading = false
          this.filterData = res.data
          if (this.isSecondCollege && this.collegeId) {
            // 过滤混合宿舍
            // 过滤混合宿舍(inRule===3) 和所属院系（collegeId相等）
            this.dormRoomList = res.data.filter((item) => item.collegeId === this.collegeId || (+item.inRule === 3))
            // 再排个序
            this.dormRoomList = this.dormRoomList.sort((a, b) =>
              a.roomName.localeCompare(b.roomName, 'zh-CN')
            )
          } else {
            this.dormRoomList = res.data
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 点击树节点
    handleNodeClick(data, node) {
      this.dormRadio = -1
      if (node.level === 2) {
        // 二级时获取楼层信息
        this.currentFloor = data
        this.currentFloor.building = this.dormFloorTree.filter(
          (item) => item.dormitoryInfoId === data.dormitoryInfoId
        )[0]
        this.currentFloorId = data.id
        this.renderTable()
      }
    },
    // 获取楼层树
    getDormTree(query) {
      this.$http.getDormTree(query).then((res) => {
        // 过滤宿舍
        this.dormFloorTree = res.data.map((item) => {
          item.children.map((item2) => {
            delete item2.children
            item2.floorAmount = item.floorAmount
            return item2
          })
          return item
        })
        this.dormFloorTree = res.data
        this.currentFloor = this.dormFloorTree[0].children[0]
        this.currentFloorId = this.dormFloorTree[0].children[0].id
        this.currentFloor.building = this.dormFloorTree.filter(
          (item) => item.dormitoryInfoId === this.currentFloor.dormitoryInfoId
        )[0]
        this.renderTable()
      })
    },
    // 关闭选择学生跳窗
    closeSelectClass() {
      this.selectClass.isOpen = false
    },
    // 导出宿舍数据
    exportDormRoom() {
      this.$http.exportExcelHttp.exportDormitoryRoom({})
    },
    // 多选控制
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleBatchDelete() {
      this.$confirm(`此操作会永久删除这${this.multipleSelection.length}个宿舍以及关联的下级床位, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const ids = this.multipleSelection.map((i) => i.id)
          deleteRoomBatch({ ids }).then(() => {
            this.$message.success('删除成功!')
            this.renderTable()
          })
        })
        .catch(() => {
          this.$message('已取消删除')
        })
    },
    // 获取是否显示【批量清空电表按钮】的状态
    async getBatchSetMeterFlag() {
      try {
        const res = await getSysParamsByKey('batchSetMeterFlag')
        copyData(res.data, this.batchSetMeterFlag)
      } catch (e) {
      }
    },
    //  控制
    handleBatchSetMeterFlag: throttle(function() {
      this.loading = true
      this.$confirm(`此操作将${JSON.parse(this.batchSetMeterFlag.paramValue) ? '显示' : '隐藏'}【批量清空电表】按钮, 是否继续?`, '提示').then(async () => {
        try {
          await addParams(this.batchSetMeterFlag)
          this.$message.success(`${JSON.parse(this.batchSetMeterFlag.paramValue) ? '按钮已显示' : '按钮已隐藏'}`)
          await this.getBatchSetMeterFlag()
        } catch (e) {
        } finally {
          this.loading = false
        }
      }).catch(() => {
        this.getBatchSetMeterFlag()
      })
    }, 1000),
    // 修改门锁设备码
    editLockCode(row) {
      this.$prompt(`请输入门锁设备码(<b>原门锁码：${row.lockFeatureCode ? row.lockFeatureCode : '未设置'}</b>)`, '修改门锁码', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^.+$/,
        inputValue: row.lockFeatureCode || '',
        dangerouslyUseHTMLString: true
      }).then(({ value }) => {
        this.$confirm('是否确认修改门锁码？').then(async () => {
          try {
            await saveLockFeatureCodeApi({
              id: row.id,
              lockFeatureCode: value
            })
            this.$message.success(`${row.roomName}门锁设备码修改成功!`)
            this.renderTable()
          } catch (e) {
            if (!e) {
              this.$message.error('数据获取失败，请重试！')
            }
          }
        })
      }).catch(() => {
      })
    }
  }
}

</script>
